@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --orange: #000000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-sidebar .brand-text {
  color: white;
}

.sidebar-dark-primary .nav-sidebar>.nav-item>.nav-link.active {
  /* background-color: var(--orange); */
  background-color: #202020;
}

.card-primary.card-outline {
  border-top: 3px solid var(--orange);
}

.content-wrapper {
  background-color: rgb(245, 245, 245);
  /* height: 1000vh; */
}

.reloadDouble {
  border-color: transparent black;
  border-radius: 50%;
  border-style: solid;
  border-width: .125em;
  height: 1.5em;
  margin: .25em;
  width: 1.5em;
}

.reloadDouble:before,
.reloadDouble:after {
  border-style: solid;
  content: '';
  display: block;
  position: absolute;
  width: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.reloadDouble:after {
  border-color: transparent transparent transparent black;
  border-width: .3125em 0 .3125em .5em;
  top: -.3125em;
  left: .0625em;
}

.reloadDouble:before {
  border-color: transparent black transparent transparent;
  border-width: .3125em .5em .3125em 0;
  bottom: -.3125em;
  right: .0625em;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

[class*=sidebar-dark-] {
  background-color: #233240 !important;
}
[class*=btn-primary] {
  background-color: #202020 !important;
}
[class*=btn-secondary] {
  background-color: #626165 !important;
}

.main-sidebar{
  z-index:  1 ;
}